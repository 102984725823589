<template>
  <div class="home">
	<div>2222662</div>
	<el-button type="danger" round>危险按钮</el-button>
  </div>
</template>

<script>

export default {
  data(){
	  return{
		  
	  }
  },
}
</script>
