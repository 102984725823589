import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush=VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push=function push(location)
{return originalPush.call(this, location).catch(err => err)}
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/Head',
    name: 'head',
    component: () => import('../views/head.vue')
  },
  {
    path: '/Bottom',
    name: 'bottom',
    component: () => import('../views/bottom.vue')
  },
  {
    path: '/Gygg',
    name: 'gygg',
    component: () => import('../views/gygg.vue')
  },
  {
    path: '/Lxwm',
    name: 'lxwm',
    component: () => import('../views/lxwm.vue')
  },
  {
    path: '/Logo',
    name: 'logo',
    component: () => import('../views/logo.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
